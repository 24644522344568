@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  @apply font-sans; /* Applies the default font-sans family set in tailwind.config.js */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.reveal .slides section{
  top: 2px !important;
  overflow: auto !important;
}

/* .reveal .slides section .canvas-container{
  margin: 0 auto !important;
} */
