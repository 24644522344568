.timeline-editor-engine .player-panel {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .timeline-editor-engine .player-panel .lottie-ani {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .timeline-editor-engine .timeline-editor {
    width: 100%;
    height: 100%;
  }
  
 /* Base styles for all .timeline-editor-action elements */
.timeline-editor-engine .timeline-editor-action {
  height: 16px !important; /* This might conflict with 'height: 100%;' */
  top: 30%;
  transform: translateY(-50%);
  cursor: pointer;
  background-position: bottom;
  background-repeat: repeat-x;
  width: 100%;
  /* height: 100%; Removing or commenting out as it conflicts with 'height: 18px;' */
  font-size: 11px;
  color: #fff;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
 
}

/* Specific styles for .effect0 */
.timeline-editor-engine .timeline-editor-action .effect0 {
  background-color: #cd9541; /* Example color for effect0 */
  width: 100%; 
  height: 100%; 
  border-radius: 5px;
}

/* Specific styles for .effect1 */
.timeline-editor-engine .timeline-editor-action .effect1 {
  background-color: rgb(98, 21, 207); 
  width: 100%; 
  height: 100%; 
  border-radius: 5px;
}
/* Specific styles for .effect1 */
.timeline-editor-engine .timeline-editor-action .effect2 {
  background-color: #FFA06E;
  width: 100%; 
  height: 100%; 
  border-radius: 5px;
}
/* Specific styles for .effect3 */
.timeline-editor-engine .timeline-editor-action .effect3 {
  background-color: #283e0e;
  width: 100%; 
  height: 100%; 
  border-radius: 5px;
  background-image: url('../../../public/soundWave.png');;
}
.timeline-editor-engine .timeline-editor-action .effect3 .effect-text{
  width: auto;
}
.timeline-editor-engine .timeline-editor-action .effect4 {
  background-color: #5e6df5;
  width: 100%; 
  height: 100%; 
  border-radius: 5px;
}
.timeline-editor-engine .timeline-editor-action .effectbutton {
  background-color: #186ce9;
  width: 100%; 
  height: 100%; 
  border-radius: 5px;
}
.timeline-editor-engine .timeline-editor-action .effecttextInput {
  background-color: #f78adf;
  width: 100%; 
  height: 100%; 
  border-radius: 5px;
}
.timeline-editor-engine .timeline-editor-action .effectcard {
  background-color: #94a3b8;
  width: 100%; 
  height: 100%; 
  border-radius: 5px;
}
.timeline-editor-engine .timeline-editor-action .effectquiz {
  background-color: #ecb3b3;
  width: 100%; 
  height: 100%; 
  border-radius: 5px;
}
.timeline-editor-engine .timeline-editor-action .effect-text {
  display: flex;
  align-items: center; /* Horizontally centers the content */
  justify-content: center; /* Vertically centers the content */
  text-align: center; /* Ensures text alignment for the content inside */
  width: 100%; /* Ensure it takes the full width of its parent */
  height: 100%; /* Ensure it takes the full height of its parent */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
  
  
  .timeline-editor-engine .timeline-editor-action-left-stretch::after {
    border-left: 7px solid rgba(255, 255, 255, 0.4);
  }
  
  .timeline-editor-engine .timeline-editor-action-right-stretch::after {
    border-right: 7px solid rgba(255, 255, 255, 0.4);
  }
  
  .timeline-editor-engine .timeline-player {
    height: 18px;
    width: 100%;
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: black;
    color: #ddd;
  }
  .timeline-editor-engine .timeline-editor-cursor{
    top: 0;
  }
  /* src/components/Timeline/index.css */
/* Add styles for the expand button if not using Tailwind CSS */

  div[aria-label="grid"] {
    /* Your custom styles here */
    padding: 0px;
    margin: 0px;
    font-size: 10px;
    height: 100%;
    margin-top: 0px;
  }
  
  .timeline-editor-engine .timeline-player .play-control {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
  }
  
  .timeline-editor-engine .timeline-player .time {
    font-size: 11px;
    margin: 0 20px;
    width: 70px;
  }
  
  .timeline-editor-engine .timeline-player .rate-control {
    justify-self: flex-end;
    display: flex;
  }
  
  .timeline-editor-engine .timeline-player .rate-control select {
    width: 90px !important;
    font-size: 11px;
    border: none;
    box-shadow: none !important;
    background-color: transparent;
    color: #ddd;
  }
  
  .timeline-editor-engine .timeline-player .rate-control select option {
    font-size: 11px;
    display: flex;
    justify-content: center;
  }
  
  .timeline-editor-engine .timeline-player .rate-control .ant-select-arrow {
    color: #ddd;
  }
  

/* Audio recorder style */
.audio-recorder-component .audio-recorder-mic {
padding: 10px;
}

.audio-recorder-component .audio-recorder-timer  {
  margin-left: 0;
  font-size: 12px;
}

.audio-recorder-component .audio-recorder-status {
  margin-left: 10px;
  font-size: 12px;
}
